@media (min-width: 1151px) {
    .page-component-pb {
        height: calc(100vh - 68px);

        .body-container {
            height: 100%;
            position: relative;
            background: #f2f2f2;
            //background-image: url("../../images/vecteezy_blue-background_bw1120.jpg");
            background-repeat: round;
            height: 93vh !important;

            &.left {
                .side-bar {
                    width: 50px;
                    border: none;
                    -webkit-transition: width .5s ease;
                    -o-transition: width .5s ease;
                    transition: width .5s ease;

                    ul>li>a {
                        div {
                            svg {
                                width: 2rem;
                                height: 2rem;
                                -webkit-transition: width .4s ease .4s, height .4s ease .4s, color 0.4s ease;
                                -o-transition: width .4s ease .4s, height .4s ease .4s, color 0.4s ease;
                                transition: width .4s ease .4s, height .4s ease .4s, color 0.4s ease;
                            }
                        }
                    }
                }

                .body-context {
                    margin-left: 50px;
                    -webkit-transition: margin .5s ease;
                    -o-transition: margin .5s ease;
                    transition: margin .5s ease;
                }

                .nav-btn {
                    margin-left: 50px;
                    -webkit-transition: margin .5s ease;
                    -o-transition: margin .5s ease;
                    transition: margin .5s ease;

                    div {
                        cursor: e-resize;
                    }
                }
            }

            &.right {
                .right-side-bar {
                    transform: translateX(0%);
                    // box-shadow:0.350rem 0px .25rem -0.25rem rgba(0,0,0,.075);
                }
            }

            .nav-btn {
                position: absolute;
                height: 100%;
                display: flex;
                align-items: center;
                margin-left: 270px;
                -webkit-transition: margin .5s ease-out .3s;
                -o-transition: margin .5s ease-out .3s;
                transition: margin .5s ease-out .3s;

                div {
                    z-index: 3;
                    width: 16px;
                    height: 30px;
                    background: coral;
                    border-bottom-right-radius: 11px;
                    border-top-right-radius: 11px;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    cursor: e-resize;

                    svg {
                        width: 1.2em;
                        height: 1.2em;
                    }
                }
            }

            .side-bar-min {
                display: none;
            }

            .side-bar {
                overflow-x: hidden;
                display: block;
                z-index: 3;
                position: absolute !important;
                border: none;
                left: 0px;
                height: 100%;
                top: 0px;
                width: 270px;
                max-width: 100vw;
                box-shadow: none;
                -webkit-transition: width .5s ease-out .3s;
                -o-transition: width .5s ease-out .3s;
                transition: width .5s ease-out .3s;

                ul {
                    li {
                        a {
                            padding: 8px 8px 8px 15px;
                            text-decoration: none;
                            font-size: 1.5rem;
                            color: #f1f1f1;
                            display: flex;
                            flex-direction: row;
                            white-space: nowrap !important;
                            -webkit-transition: color .4s ease;
                            -o-transition: color .4s ease;
                            transition: color .4s ease;

                            div {
                                display: flex;
                                align-items: center;

                                svg {
                                    color: #f1f1f1;
                                    width: 1.5rem;
                                    height: 1.5rem;
                                    margin-right: 14px;
                                    -webkit-transition: width .2s ease, height .2s ease, color 0.4s ease;
                                    -o-transition: width .2s ease, height .2s ease, color 0.4s ease;
                                    transition: width .2s ease, height .2s ease, color 0.4s ease;

                                }
                            }

                            &:hover {
                                color: #a9a5a5;

                                div {
                                    svg {
                                        color: #a9a5a5;
                                    }
                                }
                            }

                            &.active,
                            &.active.hover {
                                color: coral;

                                div {
                                    svg {
                                        color: coral;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right-side-bar {
                display: block;
                background-color: #232530;
                position: absolute !important;
                right: 0px;
                height: 100%;
                top: 0px;
                width: 50vw;
                max-width: 50vw;
                transform: translateX(100%);
                -webkit-transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                -o-transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                z-index: 10;

                .form-container {
                    align-items: center;
                }

                .c-form-frame {
                    width: 100%;
                    max-width: 800px;
                    display: block;
                    padding: 1rem 0px;

                }
            }

            .body-context {
                transition: margin-left .5s;
                height: 100%;
                margin-left: 270px;
                -webkit-transition: margin .5s ease-out .3s;
                -o-transition: margin .5s ease-out .3s;
                transition: margin .5s ease-out .3s;
            }



        }
    }
}

@media (max-width: 1150px) and (min-width: 769px) {
    .page-component-pb {
        height: calc(100vh - 68px);

        .part-header-container {
            display: flex;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            height: 45px;
            box-sizing: border-box;
            background: white;
            position: relative;
            z-index: 0;

            .buttons {
                display: flex;


            }

            .part-header {
                padding: 0;
                margin: 0;
                font-weight: normal;
            }

            .burger-container {
                display: flex;
                flex-direction: row;
                align-items: center;

                .burger-p {
                    display: block;
                    margin-right: 10px;
                    margin-left: 10px;
                    border: 1px solid rgb(19, 170, 82);
                    border-radius: 4px;
                    padding: 1px 3px 1px 3px;

                    div {
                        width: 18px;
                        background-color: rgb(19, 170, 82);
                        margin: 5px;
                        height: 2px;
                        transition: all 0.3s ease;
                    }

                    &.toggle {

                        .line1 {
                            transform: rotate(-45deg) translate(-5px, 5px);
                        }

                        .line2 {
                            opacity: 0;
                        }

                        .line3 {
                            transform: rotate(45deg) translate(-5px, -5px);
                        }
                    }
                }
            }
        }

        .body-container {
            height: calc(100% - 45px);
            position: relative;
            background: #f2f2f2;

            &.left {
                &.left {
                    .side-bar {
                        width: 50px;
                        border: none;
                        -webkit-transition: width .5s ease;
                        -o-transition: width .5s ease;
                        transition: width .5s ease;

                        ul>li>a {
                            div {
                                svg {
                                    width: 2rem;
                                    height: 2rem;
                                    -webkit-transition: width .4s ease .4s, height .4s ease .4s, color 0.4s ease;
                                    -o-transition: width .4s ease .4s, height .4s ease .4s, color 0.4s ease;
                                    transition: width .4s ease .4s, height .4s ease .4s, color 0.4s ease;
                                }
                            }
                        }
                    }

                    .body-context {
                        margin-left: 50px;
                        -webkit-transition: margin .5s ease;
                        -o-transition: margin .5s ease;
                        transition: margin .5s ease;
                    }

                    .nav-btn {
                        margin-left: 50px;
                        -webkit-transition: margin .5s ease;
                        -o-transition: margin .5s ease;
                        transition: margin .5s ease;

                        div {
                            cursor: e-resize;
                        }
                    }
                }
            }

            &.right {
                .right-side-bar {
                    transform: translateX(0%);
                    // box-shadow:0.350rem 0px .25rem -0.25rem rgba(0,0,0,.075);
                }
            }

            .side-bar-min {
                display: none;
            }

            .side-bar {
                overflow-x: hidden;
                display: block;
                z-index: 3;
                position: absolute !important;
                border: none;
                left: 0px;
                height: 100%;
                top: 0px;
                width: 270px;
                max-width: 100vw;
                box-shadow: none;
                -webkit-transition: width .5s ease-out .3s;
                -o-transition: width .5s ease-out .3s;
                transition: width .5s ease-out .3s;

                ul {
                    li {
                        a {
                            padding: 8px 8px 8px 15px;
                            text-decoration: none;
                            font-size: 1.5rem;
                            color: #f1f1f1;
                            display: flex;
                            flex-direction: row;
                            white-space: nowrap !important;
                            -webkit-transition: color .4s ease;
                            -o-transition: color .4s ease;
                            transition: color .4s ease;

                            div {
                                display: flex;
                                align-items: center;

                                svg {
                                    color: #f1f1f1;
                                    width: 1.5rem;
                                    height: 1.5rem;
                                    margin-right: 14px;
                                    -webkit-transition: width .2s ease, height .2s ease, color 0.4s ease;
                                    -o-transition: width .2s ease, height .2s ease, color 0.4s ease;
                                    transition: width .2s ease, height .2s ease, color 0.4s ease;

                                }
                            }

                            &:hover {
                                color: #a9a5a5;

                                div {
                                    svg {
                                        color: #a9a5a5;
                                    }
                                }
                            }

                            &.active,
                            &.active.hover {
                                color: coral;

                                div {
                                    svg {
                                        color: coral;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right-side-bar {
                display: block;
                background-color: #f9fbfa;
                position: absolute !important;
                border-bottom: 1px solid #e7eeec;
                border-left: 1px solid #e7eeec;
                border-top: 1px solid #e7eeec;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                right: 0px;
                height: 100%;
                top: 0px;
                width: 500px;
                max-width: 100%;
                transform: translateX(100%);
                -webkit-transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                -o-transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                z-index: 10;
            }


            .body-context {
                transition: margin-left .5s;
                padding: 15px 0px 15px 10px;
                height: 100%;
            }
        }
    }

}

@media (max-width: 768px) {
    .page-component-pb {
        height: calc(100vh - 68px);

        .part-header-container {
            display: flex;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            height: 45px;
            box-sizing: border-box;
            background-color: white;
            position: relative;
            z-index: 0;

            .buttons {
                display: flex;
            }

            .part-header {
                padding: 0;
                margin: 0;
                font-weight: normal;
            }

            .burger-container {
                display: flex;
                flex-direction: row;
                align-items: center;

                .burger-p {
                    display: block;
                    margin-right: 10px;
                    margin-left: 10px;
                    border: 1px solid rgb(19, 170, 82);
                    border-radius: 4px;
                    padding: 1px 3px 1px 3px;

                    div {
                        width: 18px;
                        background-color: rgb(19, 170, 82);
                        margin: 5px;
                        height: 2px;
                        transition: all 0.3s ease;
                    }

                    &.toggle-m {

                        .line1 {
                            transform: rotate(-45deg) translate(-5px, 5px);
                        }

                        .line2 {
                            opacity: 0;
                        }

                        .line3 {
                            transform: rotate(45deg) translate(-5px, -5px);
                        }
                    }
                }
            }
        }

        .body-container {
            height: calc(100% - 45px);
            position: relative;
            background: #f2f2f2;

            &.left {
                .side-bar-min {
                    transform: translateX(-100%);
                    //box-shadow:0.350rem 0px .25rem -0.25rem rgba(0,0,0,.075);
                }
            }

            &.right {
                .right-side-bar {
                    transform: translateX(0%);
                    // box-shadow:0.350rem 0px .25rem -0.25rem rgba(0,0,0,.075);
                }
            }

            .side-bar {
                display: none;
            }

            .side-bar-min {
                display: block;
                z-index: 3;
                padding-top: 15px;
                background-color: #f9fbfa;
                position: absolute !important;
                border: none;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
                left: 0px;
                height: 100%;
                top: 0px;
                width: 100%;
                max-width: 100vw;
                box-shadow: none;
                transform: translateX(0%);
                -webkit-transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                -o-transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                transition: transform 0.5s ease, box-shadow 0.1s ease-in;

                ul {
                    margin-block: 0px;
                    padding-inline-start: 0px;
                    padding: 0px;
                    list-style-type: none;

                    li {
                        width: 100%;

                        a {
                            margin: 0px;
                            appearance: none;
                            background: none rgba(249, 251, 250, 0);
                            border: none;
                            width: 100%;
                            min-height: 32px;
                            padding: 12px 5px 12px 25px;
                            box-sizing: border-box;
                            display: flex;
                            -webkit-box-align: center;
                            align-items: center;
                            font-family: 'Roboto', sans serif;
                            text-align: left;
                            text-transform: capitalize;
                            color: rgb(61, 79, 88);
                            transition: background-color 150ms ease-in-out 0s;
                            font-size: 18px;
                            line-height: 10px;
                            cursor: default;
                            text-decoration: none;
                            position: relative;

                            &.active {
                                color: #13aa52;
                            }
                        }
                    }
                }
            }

            .right-side-bar {
                display: block;
                background-color: #f9fbfa;
                position: absolute !important;
                border-bottom: 1px solid #e7eeec;
                border-left: 1px solid #e7eeec;
                border-top: 1px solid #e7eeec;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                right: 0px;
                height: 100%;
                top: 0px;
                width: 100%;
                max-width: 100%;
                transform: translateX(100%);
                -webkit-transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                -o-transition: transform 0.5s ease, box-shadow 0.1s ease-in;
                transition: transform 0.5s ease, box-shadow 0.1s ease-in;
            }

            .body-context {
                transition: margin-left .5s;
                padding: 15px 0px 0px 0px;
                height: 100%;
            }
        }
    }

}





/*
.left-menu{
    background-color: #f1f1f1;
    position: absolute !important;
    padding-top: 5%;
    padding-bottom: 10%;
    left: 0px;
    height: calc(100vh - 105px);
    top: 105px;
    width: 300px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    box-shadow: none;
    transform: translateX(-100%);
    -webkit-transition: transform 0.5s ease , box-shadow 0.1s ease-in;
    -o-transition: transform 0.5s ease , box-shadow 0.1s ease-in;
    transition: transform 0.5s ease , box-shadow 0.1s ease-in;

    &.nav-active{
        transform: translateX(0%);
        box-shadow:0.350rem 0px .25rem -0.25rem rgba(0,0,0,.075);
    }
}

.right-menu{
    background-color: #f1f1f1;
    position: absolute !important;
    padding-top: 5%;
    padding-bottom: 10%;
    right: 0px;
    height: 90vh;
    top: 10vh;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    box-shadow: none;
    transform: translateX(100%);
    -webkit-transition: transform 0.5s ease , box-shadow 0.1s ease-in;
    -o-transition: transform 0.5s ease , box-shadow 0.1s ease-in;
    transition: transform 0.5s ease , box-shadow 0.1s ease-in;

    &.nav-active{
        transform: translateX(0%);
        box-shadow:-0.350rem 0px .25rem -0.25rem rgba(0,0,0,.075);
    }
}
*/