.modal-content{    
    border:1px solid rgba(0,0,0,.2) !important
    
}

.form-footer{
    border-top: 1px solid rgb(231, 238, 236);
    border-bottom: inherit;
    border-radius: 0px 0px 7px 7px;
    padding: 5px;
    position: relative;
    display: flex;
    justify-content: right;
    margin-top: 10px;

    button{
        background-color: #f9fbfa;
        box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px;
        color: black;
        border-radius: 4px;
        display: flex;
        align-content: center;

        &:focus{
            box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px !important;
            background-color: white;
        }
        
        &:active {
            box-shadow: rgb(6 22 33 / 30%) 0px .5px 1px !important;
            transform: translateY(1px);
            color: rgb(61, 79, 88);
        }
    }
}