@media (min-width: 769px) {
    .insurance-container{
        width:100%;
        height:100%;

        .list-container{
            height: 100%;

           
        }
        .searchInput{
            height: 25.56px;
            padding: 0px 3px;
           
        }
        .downs{
            width: 250px;
        }

       
    }
}

@media (max-width: 768px) {
    .insurance-container{
        width:100%;
        height:100%;
        padding-bottom: 3px;

        .list-container{
            height: 100%;

           
        }

       
    }
}