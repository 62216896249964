.header-buttons{

   
     button{
        background-color: #f9fbfa;
        border: 1px solid #bfcacd;
        box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px;
        color: rgb(61, 79, 88);
        border-radius: 4px;
        display: flex;
        align-content: center;

        &:focus{
            box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px !important;
        }

        &:hover{
            background-color: white;
        }

        &:active {
            box-shadow: rgb(6 22 33 / 30%) 0px .5px 1px !important;
            transform: translateY(1px);
            color: rgb(61, 79, 88);
        }

        svg{
            color: #13aa52;
        }

        span{
            margin-left: 4px;
        }
    }
}
.inputfile{
    width: 0.1px;
    height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1
}
#atama,#atamaFormButton{
    background-color: #f9fbfa;
    border: 1px solid #bfcacd;
    box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px;
    color: rgb(61, 79, 88);
    border-radius: 4px;
    display: flex;
    align-content: center;

    &:focus{
        box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px !important;
    }

    &:hover{
        background-color: white;
    }

    &:active {
        box-shadow: rgb(6 22 33 / 30%) 0px .5px 1px !important;
        transform: translateY(1px);
        color: rgb(61, 79, 88);
    }

    span{
        margin-left: 4px;
    }
}