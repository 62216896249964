@media (min-width: 769px) {
    .company-information.c-form-frame{
        display: flex;
        justify-content: center;        
        
        .form-containers{
            box-shadow: rgb(6 22 33 / 30%) 0px 4px 10px -4px;
            background-color: rgb(255, 255, 255);
            max-width: 1000px;
            width: 100%;
            position: relative;
            border-radius: .5rem;


            display: flex;
            flex-direction: column;

            .form-header{
                padding: 5px;
                position: relative;
                background-color: #e9ecef;               
                width:100%;
                height: 42px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color:#212529;
                border-top-left-radius: .3rem;
                border-top-right-radius: .3rem;

                .header{
                    span{
                        font-weight:400;
                        font-size: 15px;
                        margin-left: 6px;
                    }
                }

                .header-buttons{

                    button{
                        background-color: #f9fbfa;
                        border: none;
                        box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px;
                        color: #f1f1f1;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        height: 28px;


                        &:hover{
                            background-color: white;
                        }

                        &:active {
                            box-shadow: rgb(6 22 33 / 30%) 0px .5px 1px;
                            transform: translateY(1px);
                        }

                        svg{
                            color: darkgreen;
                        }

                        span{
                            margin-left: 4px;
                            color: black;
                            font-family: inherit;
                            font-size: 13px;
                        }
                    }
                    
                }
                
            }
        
            #FirmaBilgileri{
                margin: 0 5% 0 5%;
                padding-top: 10px;

                .rows{
                    display: flex;
                    flex-wrap: wrap;

                    .kol{padding: 0px;}
                }

                .form-group{
                    display:flex;
                    flex-direction:row;

                    .label{
                        text-align: end !important;
                        padding-top:4px !important;
                        min-width:122px !important;

                        label{
                            white-space:nowrap;
                            color:#495057;
                            font-weight: unset;
                        }
                    }

                    .input{
                        padding-left:5px;
                        flex-grow:1 ;

                        input{
                            font-family: 'Roboto', sans-serif;
                            font-size: 13px ;
                            color:#212529;
                            line-height: 1.4 !important;
                            //height: calc(1.5em + 0.5rem);

                            &:disabled{
                                background: rgb(249, 251, 250);
                            }
                            
                        }

                        textarea{
                            font-family: 'Roboto', sans-serif;
                            font-size: 13px ;
                            color:#212529;
                            line-height: 1.4 !important;

                            &:disabled{
                                background: rgb(249, 251, 250);
                            }
                            
                        }
                    }

                    &:nth-last-child(1){
                    // margin-bottom: 0px;
                    }

                }

                .form-footer{
                    border-top: 1px solid rgb(231, 238, 236);
                    border-bottom: inherit;
                    border-radius: 0px 0px 7px 7px;
                    padding: 5px;
                    position: relative;
                    display: flex;
                    justify-content: right;
                    margin-top: 10px;

                    button{
                        background-color: #f9fbfa;
                        box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px;
                        color: black;
                        border-radius: 4px;
                        display: flex;
                        align-content: center;

                        &:focus{
                            box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px !important;
                        }

                        &:hover{                               
                            background-color: white;
                        }

                        &:active {
                            box-shadow: rgb(6 22 33 / 30%) 0px .5px 1px !important;
                            transform: translateY(1px);
                            color: rgb(61, 79, 88);
                        }
                    }
                }
            
            }

        }
    }    
}

@media (max-width: 768px) {
    .c-form-frame{
        display: flex;
        justify-content: center;
        
        .form-containers{
            border: 1px solid rgb(231, 238, 236);
            box-shadow: rgb(6 22 33 / 30%) 0px 4px 10px -4px;
            border-radius: 7px;
            background-color: rgb(255, 255, 255);
            max-width: 1000px;
            width: 100%;
            position: relative;


            display: flex;
            flex-direction: column;

            .form-header{
                border-bottom: 1px solid rgb(231, 238, 236);
                border-radius: 7px 7px 0px 0px;
                padding: 5px;
                position: relative;
                background-color: rgb(249, 251, 250);
                width:100%;
                min-height: 35px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .header{
                    span{
                        font-weight:400;
                        font-size: 15px;
                        margin-left: 6px;
                    }
                }

                .header-buttons{

                    button{
                        background-color: #f9fbfa;
                        border: 1px solid #bfcacd;
                        box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px;
                        color: rgb(61, 79, 88);
                        border-radius: 4px;
                        display: flex;
                        align-content: center;
                        
                        &:active {
                            background-color: white;
                            box-shadow: rgb(6 22 33 / 30%) 0px .5px 1px;
                            transform: translateY(1px);
                        }

                        svg{
                            color: #13aa52;
                        }

                        span{
                            margin-left: 4px;
                        }
                    }
                    
                }
                
            }
        
            #FirmaBilgileri{
                margin: 0 5px 0 5px;
                padding-top: 10px;

                .rows{
                    display: flex;
                    flex-wrap: wrap;

                    .kol{padding: 0px;}
                }

                .form-group{
                    display:flex;
                    flex-direction:row;

                    .label{
                        text-align: end !important;
                        padding-top:4px !important;
                        min-width:122px !important;

                        label{
                            white-space:nowrap;
                            color:#495057;
                            font-weight: unset;
                        }
                    }

                    .input{
                        padding-left:5px;
                        flex-grow:1 ;

                        input{
                            font-family: Roboto, sans-serif;
                            font-size: 13px ;
                            color:#212529;
                            line-height: 1.4 !important;
                           // height: calc(1.5em + 0.5rem);

                            &:disabled{
                                background: rgb(249, 251, 250);
                            }
                            
                        }

                        textarea{
                            font-family: 'Roboto', sans-serif;
                            font-size: 13px ;
                            color:#212529;
                            line-height: 1.4 !important;

                            &:disabled{
                                background: rgb(249, 251, 250);
                            }
                            
                        }                      
                                            

                       
                    }

                    &:nth-last-child(1){
                    // margin-bottom: 0px;
                    }

                }

                .form-footer{
                    border-top: 1px solid rgb(231, 238, 236);
                    border-bottom: inherit;
                    border-radius: 0px 0px 7px 7px;
                    padding: 5px;
                    position: relative;
                    display: flex;
                    justify-content: right;
                    margin-top: 10px;

                    button{
                        background-color: #f9fbfa;
                        border: 1px solid #bfcacd;
                        box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px;
                        color: rgb(61, 79, 88);
                        border-radius: 4px;
                        display: flex;
                        align-content: center;
                        

                        &:active {
                            background-color: white;
                            box-shadow: rgb(6 22 33 / 30%) 0px .5px 1px;
                            transform: translateY(1px);
                            color: rgb(61, 79, 88);
                        }
                    }
                }
            
            }

        }
    }    
}