@media (min-width: 769px) {
    .part-header-container {
        display: flex;
        -webkit-box-align: center;
        justify-content: flex-end;
        align-items: center;
        height: 50px;
        z-index: 0;
        background: rgb(137 132 121);
        box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
        backdrop-filter: blur(15.6px);
        padding-bottom: 2px;

        .buttons {
            display: flex;

            button {
                margin: 0px 4px;
            }
        }

        .part-header {
            padding: 0;
            margin: 0;
            font-weight: normal;
        }
    }

    .company-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: calc(100% - 50px);
        padding: 1rem 0 1rem 1.5rem;
        background: rgb(137 132 121);
        backdrop-filter: blur(15.6px);

        .list-container {
            width: 35%;
            display: flex;
            flex-direction: column;

            .list-alt {
                height: 100%;
            }
        }

        .content {
            flex-grow: 1;
            padding: 0 0 0 10px;
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
            gap: 10px;
            padding-right: 1.5rem;

            .searchCompany-frame {
                display: none !important;
            }

            .frame {


                &:nth-last-child(1) {
                    // margin-bottom: 10px !important;
                }
            }
        }

        .company-page.frame {
            display: flex;
            justify-content: center;

            ul {
                width: 700px;
            }
        }
    }



    .topnav {
        background-color: #333;
        overflow: hidden;
        border-radius: 0.3rem;
        height: 42px;
        display: flex;
        flex-direction: row;

        li {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 175px;

            a {
                float: left;
                color: #f2f2f2;
                text-align: center;
                text-decoration: none;
                font-size: 17px;
                width: 100%;
                padding: 11px;

                &:hover {
                    background-color: #ddd;
                    color: black;
                }

                &.active {
                    background: coral;
                    color: white;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .company-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        .list-container {
            width: 350px;
            display: none;
            flex-direction: column;
        }

        .content {
            flex-grow: 1;
            padding: 0 10px 0 10px;
            display: inline-block;
            overflow-x: hidden;
            overflow-y: auto;
            gap: 10px;

            .searchCompany-frame {
                .searchCompany-container {
                    position: relative;
                }
            }

            .frame {
                margin-bottom: 40px;

                &:nth-last-child(1) {
                    margin-bottom: 10px !important;
                }
            }
        }
    }
}