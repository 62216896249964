body {
    line-height: 1.6;
    margin: 20px;
}

select:focus,
.page-link:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
button.form-control:focus,
button.btn-sm:focus,
button.btn:focus,
button.btn-close:focus,
input.is-invalid:focus,
input.is-valid:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
label.form-control.btn:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
    box-shadow: inset 0 0px 0 #ddd !important;
    border-color: coral;
}

* {
    box-sizing: border-box;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

@media only screen and (max-width: 599px) {
    #hakkimizda-wrapper {
        min-height: 51vh;
        background: aliceblue;

        .header {
            height: 16vh;
            background: linear-gradient(117deg, rgba(8, 51, 64, 1) 0%, rgba(11, 70, 88, 1) 22%, rgba(21, 108, 134, 1) 35%, rgba(47, 150, 184, 1) 53%, rgba(82, 191, 226, 1) 71%, rgba(128, 221, 252, 1) 100%);
            display: flex;
            padding: 0 5vw;
            align-items: end;
            justify-content: center;

            h2 {
                padding-bottom: .7rem;
                color: white;
                font-weight: 300;
                font-size: 2.7rem;
            }
        }

        #content {
            display: flex;
            padding: 4rem 5vw;
            justify-content: center;

            #content-wrapper {
                font-size: 16px;
                color: black;

                span {
                    line-height: 2em;
                    font-weight: 500;
                }

                ul {
                    list-style-type: disc;
                    padding-left: 2rem;

                    li {
                        padding-bottom: .5em;

                        span {
                            font-weight: 500;
                        }
                    }
                }

                #bottom-content {
                    color: #000000;
                    font-size: 18px;
                    font-weight: 500;
                    font-size: 16px;
                    align-content: center;
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
    #hakkimizda-wrapper {
        min-height: 51vh;
        background: aliceblue;

        .header {
            height: 16vh;
            background: linear-gradient(117deg, rgba(8, 51, 64, 1) 0%, rgba(11, 70, 88, 1) 22%, rgba(21, 108, 134, 1) 35%, rgba(47, 150, 184, 1) 53%, rgba(82, 191, 226, 1) 71%, rgba(128, 221, 252, 1) 100%);
            display: flex;
            padding: 0 5vw;
            align-items: end;
            justify-content: center;

            h2 {
                padding-bottom: .7rem;
                color: white;
                font-weight: 300;
                font-size: 2.7rem;
            }
        }

        #content {
            display: flex;
            padding: 4rem 5vw;
            justify-content: center;

            #content-wrapper {
                font-size: 16px;
                color: black;

                span {
                    line-height: 2em;
                    font-weight: 500;
                }

                ul {
                    list-style-type: disc;
                    padding-left: 2rem;

                    li {
                        padding-bottom: .5em;

                        span {
                            font-weight: 500;
                        }
                    }
                }

                #bottom-content {
                    color: #000000;
                    font-size: 18px;
                    font-weight: 500;
                    font-size: 16px;
                    align-content: center;
                }
            }
        }
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    #hakkimizda-wrapper {
        min-height: 51vh;
        background: aliceblue;

        .header {
            height: 16vh;
            background: linear-gradient(117deg, rgba(8, 51, 64, 1) 0%, rgba(11, 70, 88, 1) 22%, rgba(21, 108, 134, 1) 35%, rgba(47, 150, 184, 1) 53%, rgba(82, 191, 226, 1) 71%, rgba(128, 221, 252, 1) 100%);
            display: flex;
            padding: 0 5vw;
            align-items: end;
            justify-content: center;

            h2 {
                padding-bottom: .7rem;
                color: white;
                font-weight: 300;
                font-size: 2.7rem;
            }
        }

        #content {
            display: flex;
            padding: 4rem 5vw;
            justify-content: center;

            #content-wrapper {
                font-size: 16px;
                color: black;

                span {
                    line-height: 2em;
                    font-weight: 500;
                }

                ul {
                    list-style-type: disc;
                    padding-left: 2rem;

                    li {
                        padding-bottom: .5em;

                        span {
                            font-weight: 500;
                        }
                    }
                }

                #bottom-content {
                    color: #000000;
                    font-size: 18px;
                    font-weight: 500;
                    font-size: 16px;
                    align-content: center;
                }
            }
        }
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    #hakkimizda-wrapper {
        min-height: 51vh;
        background: aliceblue;

        .header {
            height: 16vh;
            background: linear-gradient(117deg, rgba(8, 51, 64, 1) 0%, rgba(11, 70, 88, 1) 22%, rgba(21, 108, 134, 1) 35%, rgba(47, 150, 184, 1) 53%, rgba(82, 191, 226, 1) 71%, rgba(128, 221, 252, 1) 100%);
            display: flex;
            padding: 0 5vw;
            align-items: end;
            justify-content: center;

            h2 {
                padding-bottom: .7rem;
                color: white;
                font-weight: 300;
                font-size: 2.7rem;
            }
        }

        #content {
            display: flex;
            padding: 4rem 20vw;
            justify-content: center;

            #content-wrapper {
                font-size: 16px;
                color: black;

                span {
                    line-height: 2em;
                    font-weight: 500;
                }

                ul {
                    list-style-type: disc;
                    padding-left: 2rem;

                    li {
                        padding-bottom: .5em;

                        span {
                            font-weight: 500;
                        }
                    }
                }

                #bottom-content {
                    color: #000000;
                    font-size: 18px;
                    font-weight: 500;
                    font-size: 16px;
                    align-content: center;
                }
            }
        }
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1376px) {
    #hakkimizda-wrapper {
        min-height: 51vh;
        background: aliceblue;

        .header {
            height: 16vh;
            background: linear-gradient(117deg, rgba(8, 51, 64, 1) 0%, rgba(11, 70, 88, 1) 22%, rgba(21, 108, 134, 1) 35%, rgba(47, 150, 184, 1) 53%, rgba(82, 191, 226, 1) 71%, rgba(128, 221, 252, 1) 100%);
            display: flex;
            padding: 0 5vw;
            align-items: end;
            justify-content: center;

            h2 {
                padding-bottom: .7rem;
                color: white;
                font-weight: 300;
                font-size: 2.7rem;
            }
        }

        #content {
            display: flex;
            padding: 4rem 20vw;
            justify-content: center;

            #content-wrapper {
                font-size: 16px;
                color: black;

                span {
                    line-height: 2em;
                    font-weight: 500;
                }

                ul {
                    list-style-type: disc;
                    padding-left: 2rem;

                    li {
                        padding-bottom: .5em;

                        span {
                            font-weight: 500;
                        }
                    }
                }

                #bottom-content {
                    color: #000000;
                    font-size: 18px;
                    font-weight: 500;
                    font-size: 16px;
                    align-content: center;
                }
            }
        }
    }
}

@media only screen and (min-width: 1377px) {
    #hakkimizda-wrapper {
        min-height: 51vh;
        background: aliceblue;

        .header {
            height: 16vh;
            background: linear-gradient(117deg, rgba(8, 51, 64, 1) 0%, rgba(11, 70, 88, 1) 22%, rgba(21, 108, 134, 1) 35%, rgba(47, 150, 184, 1) 53%, rgba(82, 191, 226, 1) 71%, rgba(128, 221, 252, 1) 100%);
            display: flex;
            padding: 0 5vw;
            align-items: end;
            justify-content: center;

            h2 {
                padding-bottom: .7rem;
                color: white;
                font-weight: 300;
                font-size: 2.7rem;
            }
        }

        #content {
            display: flex;
            padding: 4rem 26vw;
            justify-content: center;

            #content-wrapper {
                font-size: 16px;
                color: black;

                span {
                    line-height: 2em;
                    font-weight: 500;
                }

                ul {
                    list-style-type: disc;
                    padding-left: 2rem;

                    li {
                        padding-bottom: .5em;

                        span {
                            font-weight: 500;
                        }
                    }
                }

                #bottom-content {
                    color: #000000;
                    font-size: 18px;
                    font-weight: 500;
                    font-size: 16px;
                    align-content: center;
                }
            }
        }
    }
}