.header-buttons{

    button{       
        box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px;
        border-radius: 0px;
        margin-left:2px !important;
        display: flex;
        align-content: center;
        justify-content: space-around;
        padding: .15rem .75rem;
        
        &.button-bg-green{
            background-color: #52821c;
            box-shadow: rgb(40,64,13,0.7)1px 1px 2px;
        }
        &.button-bg-purple{
            background-color: rgb(91, 93, 159);
            box-shadow: rgb(28,28,48,0.7) 1px 1px 2px;
        }
        &.button-bg-brown{
            background-color: rgb(125, 91, 23);
            box-shadow: rgb(66,48,12,0.7) 1px 1px 2px;
        }

        &:focus{
            box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px !important;
        }

        &:hover{
            background-color: white !important;
            box-shadow: rgb(6 22 33 / 30%) 1px 1px 2px !important;

            div{
                svg{
                    color:coral;
                }
                span{
                    color:coral;
                }
            }
        }

        &:active {
            box-shadow: rgb(6 22 33 / 30%) 0px 0px 1px !important;            
            color: rgb(61, 79, 88);
        }

        div{
            svg{
                color: white;
            }
            span{
                color: white;
                font-family: sans-serif;
                font-size: .85rem;
            }
        }
    }
    
}